<template>
  <div>
    <b-tabs class="shadow p-2">
      <div class="d-flex  align-items-center justify-content-between  ">
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
        </div>
        <div>
          <button
            :class="`btn btn-cart rounded ${view ? 'btn-primary': 'btn-outline-primary'}`"
            type="button"
            @click="updateView(true)"
          >
            Grid
          </button>
          <button
            :class="`btn btn-cart rounded ml-1 ${!view ? 'btn-primary': 'btn-outline-primary'}`"
            type="button"
            @click="updateView(false)"
          >
            List
          </button>
        </div>
      </div>
      <section
        v-if="!view"
        class="mt-2"
      >
        <b-card class="">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="pagination"
          />
        </b-card>
      </section>
      <b-overlay
        v-if="view"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No competitions found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-card
            v-for="(program, i) in programs"
            :key="i"
            class="ecommerce-card"
            img-src="https://picsum.photos/600/300/?image=25"
            img-alt="Image"
            img-top
            header-tag="header"
            tag="article"
          >
            <b-badge
              style="position: absolute; top: 10px; right: 10px;color: black;"
              variant="light"
            >
              Type of Competition
            </b-badge>
            <b-badge
              style="position: absolute; top: 40px; right: 10px;color: black;"
              variant="light"
            >
              Free
            </b-badge>
            <b-badge
              style="position: absolute; top: 10px; left: 10px;color: black;"
              variant="light"
            >
              C
            </b-badge>
            <div class="d-flex align-items-center ">
              <b-img
                rounded
                alt="Rounded image"
                src="https://shorturl.at/kxFUX"
                style="height: 30px; width: 30px; margin-right: 10px;"
              />
              <b-card-text> <strong>Startup Name</strong></b-card-text>
            </div>
            <div class="mt-2 mb-1">
              <b-card-title>
                {{ program.title }}
              </b-card-title>
              <div class="d-flex align-items-baseline   justify-content-between">
                <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                <b-badge class="badge badge-light-success">
                  Online
                </b-badge>
              </div>
              <b-card-text>
                Some quick example text to build on the card title and make up the bulk of the card's content.
              </b-card-text>
            </div>
            <!-- Product Actions -->
            <div class="item-options text-center d-flex ">
              <b-link
                :to="`/mentor-portal/competitions/rounds/${program.id}/${program.programs_partnerstables[0].id}`"
                class="btn btn-wishlist btn-light rounded mr-1"
                variant="light"
              >
                <span>Applications</span>
              </b-link>
              <b-link
                :to="`/mentor-portal/competitions/select/${program.id}/${program.programs_partnerstables[0].id}`"
                class="btn btn-primary btn-cart rounded  ml-1"
              >
                <span>Open</span>
              </b-link>
            </div>
            <b-card-footer class="text-center p-1  mt-1">
              <strong>Cash Prize · Prize Pool : INR 1Lakh</strong>
            </b-card-footer>
          </b-card>
        </section>
      </b-overlay>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardTitle, BLink, BOverlay, BImg, BBadge, BCardFooter, BTabs,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BTabs,
    BCardText,
    BCardFooter,
    // BCardBody,
    VueGoodTable,
    BCardTitle,
    BBadge,
    BImg,
    // BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      programs: [],
      view: true,
      rows: [],
      columns: [
        {
          label: 'Organization',
          field: 'organization',
        },
        {
          label: 'Competition Title',
          field: 'competition_title',
        },
        {
          label: 'Competition Type',
          field: 'event_type',
        },
        {
          label: 'Prize',
          field: 'prize',
        },
        {
          label: 'Begin Date',
          field: 'begin_date',
        },
        {
          label: 'End Date',
          field: 'end_date',
        },
        {
          label: 'Mode',
          field: 'mode',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      competitionItems: [
        {
          'Organized By': 'xyz', 'Competition Title': 'Startup India', 'Competetion Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Competetion Prize': 1000, 'Prize Question 1': 'question competition 1', 'Prize Question 2': 'question competition 2',
        },
      ],
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateView(view) {
      this.view = view
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
